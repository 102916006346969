<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            Profile
          </CCardHeader>
          <CCardBody>
            <CInput v-model="invoiceName" label="Your or Company Name" />
            <CInput v-model="invoiceAddress" label="Your or Company Address" />
            <CButton color="primary" @click="save()">
              Save
            </CButton>
          </CCardBody>

          <CElementCover v-if="isLoading">
            <CSpinner size="5xl" color="info"/>
          </CElementCover>

        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UserService from "../services/UserService";
import {Auth} from "aws-amplify";

export default {
  name: 'Profile',
  components: {},
  data() {
    return {
      isLoading: false,
      invoiceName: '',
      invoiceAddress: '',
      networkId: ''
    }
  },
  methods: {
    async save() {

      this.isLoading = true;
      try {

        const userData = {
          invoice_name: this.invoiceName,
          invoice_address: this.invoiceAddress,
        };

        if(this.networkId) userData.network_id = this.networkId;

        await UserService.updateData(userData);

        this.$toast.success('Changes saved successfully.');
      } finally {
        this.isLoading = false;
      }
    },
    async fetchUserData() {
      this.isLoading = true;
      try {
        const response = await UserService.getData(this.networkId);

        const {
          invoice_name = '',
          invoice_address = ''
        } = response.data;

        this.invoiceName = invoice_name;
        this.invoiceAddress = invoice_address;

      } finally {
        this.isLoading = false;
      }
    }
  },
  computed: {
  },
  async mounted() {
    const userSessionData = await Auth.currentAuthenticatedUser();
    const userGroups = userSessionData.signInUserSession.idToken.payload['cognito:groups'] || [];

    if(userGroups.includes('admin')) {
      if(this.$route.query.network_id) {

        this.networkId = this.$route.query.network_id;
      } else {
        this.$toast.error('Wrong netrowk_id')
        return false;
      }
    }

    await this.fetchUserData();
  }
}
</script>
