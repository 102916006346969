import {Auth} from "aws-amplify";
import {AbstractService} from "@/services/AbstractService";

export default new class UserService extends AbstractService {

	async updateData(data) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		return this.api.post('/user',
			data,
			{
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}

	async getData(networkId) {
		const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
		const params = {};

		if(networkId) params.network_id = networkId;

		return this.api.get('/user',
			{
				params,
				headers: {
					Authorization: jwtToken
				}
			}
		)
	}
}
